<template>
  <div class="risk-point-add-wrapper">
    <van-nav-bar
      fixed
      :title="
        !pageId
          ? `新增${TEXT_RISK_POINT_TITLE}`
          : !editable
          ? `修改${TEXT_RISK_POINT_TITLE}`
          : TEXT_RISK_POINT_TITLE
      "
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
      <template v-if="btnAuth.update" #right>
        <span v-if="editable" class="right-btn" @click="editable = false"
          >编辑</span
        >
        <template v-else>
          <span class="right-btn" @click="save">保存</span>
        </template>
      </template>
    </van-nav-bar>
    <div class="form-list">
      <van-form
        ref="vanForm"
        :show-error-message="true"
        error-message-align="right"
        @submit="onSubmit"
      >
        <van-field
          v-model="form.name"
          :readonly="editable"
          class="hide-error"
          :label-width="100"
          input-align="right"
          :label="TEXT_RISK_POINT_LABEL_NAME"
          :required="!editable"
          maxlength="32"
          :rules="[
            {
              required: true,
              message: `${TEXT_RISK_POINT_LABEL_NAME}必填`
            }
          ]"
          :placeholder="editable ? '--' : `请输入${TEXT_RISK_POINT_LABEL_NAME}`"
        ></van-field>
        <van-field
          v-model="form.areaName"
          class="hide-error"
          :is-link="!editable"
          readonly
          :required="!editable"
          :rules="[
            {
              required: true,
              message: `${TEXT_RISK_POINT_LABEL_AREA}必填`
            }
          ]"
          :label-width="100"
          input-align="right"
          :label="TEXT_RISK_POINT_LABEL_AREA"
          :placeholder="editable ? '--' : `请选择${TEXT_RISK_POINT_LABEL_AREA}`"
          @click="select('riskAreaId')"
        ></van-field>
        <van-field
          v-model="form.ratingMethod"
          readonly
          :label-width="100"
          input-align="right"
          label="判定方式"
          :rules="[
            {
              required: true,
              message: '判定方式必填'
            }
          ]"
          :required="!editable"
        >
          <div slot="input">
            <span v-if="editable">{{ form.ratingMethodName }}</span>
            <van-radio-group
              v-else
              v-model="form.ratingMethod"
              :disabled="
                editable || (form.controlList && form.controlList.length > 0)
              "
              direction="horizontal"
            >
              <van-radio
                v-for="item in ratingMethods"
                :key="item.value"
                :name="item.value"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </div>
        </van-field>
        <template v-if="isRt">
          <van-field
            v-model="form.pointType"
            readonly
            :label-width="100"
            input-align="right"
            :label="TEXT_RISK_POINT_LABEL_TYPE"
            :required="!editable"
            :rules="[
              {
                required: true,
                message: `${TEXT_RISK_POINT_LABEL_TYPE}必填`
              }
            ]"
          >
            <div slot="input">
              <span v-if="editable">{{ form.pointTypeName }}</span>
              <van-radio-group
                v-else
                v-model="form.pointType"
                :disabled="editable"
                direction="horizontal"
              >
                <van-radio
                  v-for="item in pointTypes"
                  :key="item.value"
                  :name="item.value"
                  >{{ item.label }}</van-radio
                >
              </van-radio-group>
            </div>
          </van-field>
          <van-field
            v-if="form.pointType === '1'"
            v-model="form.workType"
            :label-width="100"
            input-align="right"
            label="作业类型"
            :placeholder="editable ? '--' : '请输入作业类型'"
            maxlength="100"
          ></van-field>
          <van-field
            v-if="form.pointType === '1'"
            v-model="form.activityContent"
            class="hide-error"
            :label-width="100"
            :placeholder="editable ? '--' : '请输入作业活动内容'"
            input-align="right"
            rows="1"
            autosize
            type="textarea"
            label="作业活动内容"
            maxlength="500"
            :required="!editable"
            :rules="[
              {
                required: true,
                message: '作业活动内容必填'
              }
            ]"
          >
          </van-field>
          <van-field
            v-if="form.pointType === '2'"
            v-model="form.poi.poiName"
            :is-link="!editable"
            readonly
            :label-width="100"
            input-align="right"
            label="设备设施"
            :placeholder="editable ? '--' : '请选择设备设施'"
            @click="select('poi')"
          ></van-field>
        </template>
        <van-field
          v-model="form.reviewDate"
          :label-width="100"
          readonly
          :is-link="!editable"
          :placeholder="editable ? '--' : '请选择复评时间'"
          input-align="right"
          rows="1"
          autosize
          label="复评时间"
          @click="select('reviewDate')"
        >
        </van-field>
        <van-field
          v-if="editable && form.controlList && form.controlList.length"
          v-model="form.controlList.length"
          :label-width="100"
          readonly
          is-link
          placeholder=""
          input-align="right"
          rows="1"
          autosize
          label="危害数量"
          @click="gotoControlList(form)"
        >
        </van-field>
        <van-field
          v-model="form.remark"
          :readonly="editable"
          :label-width="60"
          :placeholder="editable ? '--' : '请输入备注'"
          input-align="right"
          rows="2"
          autosize
          type="textarea"
          label="备注"
          maxlength="500"
          show-word-limit
        >
        </van-field>
      </van-form>
    </div>
    <SelectPopupGrade
      :ref="'selectPopupGrade0'"
      v-model="form.riskAreaId"
      :filterable="true"
      :immediately="true"
      :title="popupVisibleObj['riskAreaId'].title"
      list-key="value"
      output-type="string"
      :list.sync="popupVisibleObj['riskAreaId'].list"
      :visible.sync="popupVisibleObj['riskAreaId'].visiable"
      @change="v => popupGradeChange(v, 'riskAreaId', 'areaName')"
    />
    <SelectPopupGrade
      :ref="'selectPopupGrade1'"
      v-model="form.poi.poiId"
      :filterable="true"
      title="设备设施"
      list-key="value"
      output-type="string"
      :list.sync="popupVisibleObj['poi'].list"
      :visible.sync="popupVisibleObj['poi'].visiable"
      @change="v => popupGradeChange(v, 'poi', 'poiName')"
    />
    <SelectPopupGrade
      :ref="'selectPopupGrade2'"
      v-model="form.workType"
      :filterable="true"
      title="作业类型"
      list-key="value"
      output-type="string"
      :list.sync="popupVisibleObj['workType'].list"
      :visible.sync="popupVisibleObj['workType'].visiable"
      @change="v => popupGradeChange(v, 'workType', 'workTypeName')"
    />
    <van-popup
      v-model="popupVisibleObj['reviewDate'].visiable"
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择复评时间"
        @cancel="popupVisibleObj['reviewDate'].visiable = false"
        @confirm="confirmDate"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getAreaSelect,
  getRiskAreaList,
  getRiskPointDetail2,
  getRiskWorkType,
  saveRiskPoint,
  updateRiskPointDetail
} from "@/api/psm/risk";
import addMixin from "./addMixin";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import { mapMutations, mapState } from "vuex";
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  mixins: [addMixin],
  data() {
    return {
      pageId: this.$route.query.id,
      editable: !!this.$route.query.id,
      currentDate: new Date(),
      ratingMethods: [
        {
          value: "104012",
          label: "LS"
        },
        {
          value: "104013",
          label: "LEC"
        }
      ],
      pointTypes: [
        {
          value: "1",
          label: "作业活动"
        },
        {
          value: "2",
          label: "设备设施"
        }
      ],
      form: {
        ratingMethod: "", // 评分方法
        ratingMethodName: "",
        activityContent: "", // 作业活动内容【风险点类型为作业活动时，必填，在代码中校验】
        reviewDate: "", // 计划复评时间
        workType: "", // 活动作业类型
        workTypeName: "", // 活动作业类型
        name: "", // 风险点名称
        remark: "", // 备注
        pointType: "", // 风险点类型编码【1:作业活动，2:设备设施】
        pointTypeName: "",
        riskAreaId: "", // 风险分区ID
        areaName: "", // 风险分区ID
        poi: {
          // 设备设施
          poiId: "",
          poiName: "",
          poiType: ""
        },
        controlList: []
      },
      popupVisibleObj: {
        riskAreaId: {
          title: projectTextConfig.TEXT_RISK_POINT_LABEL_AREA,
          list: [],
          visiable: false
        },
        poi: {
          title: "设备设施",
          list: [],
          visiable: false
        },
        workType: {
          title: "作业类型",
          list: [],
          visiable: false
        },
        reviewDate: {
          visiable: false
        }
      }
    };
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    })
  },
  watch: {
    "form.riskAreaId": {
      handler(newV) {
        this.getRiskAreaList(newV);
      }
    }
  },
  created() {
    this.getSelect();
    this.getRiskPointDetail();
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    cancel() {
      this.editable = true;
      this.resetDetail(this.bakDetail);
    },
    onClickLeft() {
      if (this.pageId) {
        // 如果是详情
        if (this.editable) {
          // history.go(-1);
          this.$router.replace({ name: "RiskPoint" });
        } else {
          this.cancel();
        }
      } else {
        // 新增的话直接返回
        // history.go(-1);
        this.$router.replace({ name: "RiskPoint" });
      }
    },
    resetDetail(res) {
      if (!res.poi) {
        res.poi = {
          poiName: "",
          poiId: "",
          poiType: ""
        };
      }
      for (let key in this.form) {
        this.form[key] = res[key];
      }
      if (res.reviewDate) {
        this.currentDate = new Date(res.reviewDate);
      }
    },
    // 跳转到危害列表
    gotoControlList(row) {
      this.$router.push({ name: "RiskDangerList", params: row });
    },
    confirmDate(v) {
      this.form.reviewDate = dayjs(v).format("YYYY-MM-DD");
      this.popupVisibleObj["reviewDate"].visiable = false;
    },
    getRiskPointDetail() {
      const { id } = this.$route.query;
      if (!id) {
        return;
      }
      getRiskPointDetail2(id).then(res => {
        this.bakDetail = cloneDeep(res);
        this.resetDetail(res);
      });
    },
    popupGradeChange(v, prop, propName) {
      const { value, label, poiType } = v[0];
      if (prop === "riskAreaId") {
        this.form[propName] = label;
        // 区域
        this.form["poi"]["poiName"] = "";
        this.form["poi"]["poiId"] = "";
        // 去查询设备设施
        this.getRiskAreaList(value);
      } else if (prop === "poi") {
        this.form["poi"]["poiName"] = label;
        this.form["poi"]["poiType"] = poiType;
      } else if (prop === "workType") {
        this.form[propName] = label;
      }
    },
    select(prop) {
      if (this.editable) {
        return;
      }
      this.popupVisibleObj[prop].visiable = true;
    },
    save() {
      console.log("form", this.form);
      this.$refs.vanForm.submit();
    },
    onSubmit() {
      if (this.pageId) {
        // 更新
        updateRiskPointDetail(this.pageId, this.form).then(res => {
          if (!res) {
            this.$toast.fail("修改失败");
            return;
          }
          this.setNeedRefreshList({ value: true });
          this.getRiskPointDetail();
          this.editable = true;
          this.$toast.success("修改成功");
        });
        return;
      }
      // 保存风险点
      saveRiskPoint(this.form).then(res => {
        if (!res) {
          this.$toast.success("保存失败");
          return;
        }
        // 提示
        this.setNeedRefreshList({ value: true });
        this.$toast.success("保存成功");
        this.onClickLeft();
      });
    },
    getRiskAreaList(id) {
      getRiskAreaList({ riskAreaId: id }).then(res => {
        res.forEach(item => {
          item.label = item.poiName;
          item.value = item.poiId;
        });
        this.popupVisibleObj["poi"].list = res;
      });
    },
    getSelect() {
      getAreaSelect().then(res => {
        this.popupVisibleObj["riskAreaId"].list = res;
      });
      getRiskWorkType().then(res => {
        res.map(item => {
          item.label = item.name;
          item.value = item.id;
        });
        this.popupVisibleObj["workType"].list = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-point-add-wrapper {
  padding: 60px 0 20px 0;
  .right-btn {
    font-size: 16px;
    &:first-child {
      margin-right: 8px;
    }
  }
  .hide-error {
    ::v-deep {
      .van-field__error-message {
        display: none;
      }
    }
  }
}
</style>
